section.cart {
  border: $divider;

  h2 {
    border-bottom: $divider;
    color: $gray;
    font-size: 26px;
  }

  button.primary.purchase {
    font-size: 22px;
    padding: 18px;
    border: none;

    &:hover {
      background-color: darken($gold, 5%);
      color: white;
    }
  }

  .ticket {
    .ticket-date {
      margin-top: 4px;
      font-size: 15px;
    }
  }
}

.filters {
  border-bottom: $divider;
  padding-bottom: 24px;
  margin-bottom: 32px;
}

.event-listing {
  article.event {
    border-bottom: $divider;

    .subtitle {
      font-family: $secondary-font;
      color: $gold;
      margin-bottom: 12px;
      font-size: 18px;
    }
  }
}

.stepper > .count {
  font-family: $secondary-font;
}

.selection {
  .select-tickets {
    .ticket-type:not(:last-child) {
      border-bottom: $divider;
      margin-bottom: 24px;
      padding-bottom: 24px;
    }

    .ticket-type .price-container {
      font-family: $secondary-font;
      color: $gray;
    }
  }
}

.select-session.event-sessions {
  .radio-items {
    label.radio-item {
      line-height: 18px;
    }
  }
}

.content-detail-page {
  .ticket-group-description-wrapper {
    margin: 8px 0 24px;

    > h2 {
      margin-bottom: 4px;
    }
  }

  .mobile-event {
    .summary,
    .summary p {
      line-height: 1.3;
    }
  }

  .mobile-event-description {
    p {
      line-height: 1.3;
    }
  }

  .ticket-type-description .subtitle {
    margin-top: 4px;
  }

  figure {
    margin-bottom: 24px;
  }

  .content .subtitle {
    color: $gold;
    font-family: $secondary-font;
    font-size: 20px;
    border-bottom: $divider;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }

  &.membership .select-tickets .section-header {
    margin-top: 18px;
  }
}

.survey {
  label.form-input {
    span.label {
      margin-bottom: 4px;
    }

    p {
      margin: 0;
    }

    input[type='text'] {
      margin-top: 8px;
    }
  }
}

.checkout-route {
  h2 {
    margin-bottom: 12px;
  }

  .purchaser-details,
  .payment,
  .terms-conditions,
  .upsell {
    &:not(:last-child) {
      border-bottom: $divider;
      padding-bottom: 32px;
      margin-bottom: 32px;
    }
  }
}

input[type='checkbox'] {
  border-radius: 0;
  border-color: $input-border-color;

  &:hover {
    border-color: $input-border-color-hover;
  }

  &:checked {
    background-color: transparent;
    border-color: $input-border-color-hover;

    &:before {
      border-color: $gold;
    }
  }
}

div.message.members-banner.logged-out {
  background-color: $light-gray;
  font-family: $secondary-font;
}

.complete {
  .main {
    padding: 0 24px;

    h1 {
      text-transform: none;
      font-size: 28px;

      @include large {
        font-size: 32px;
      }

      span {
        text-transform: uppercase;
        margin-bottom: 8px;
        font-size: 45px;

        @include large {
          font-size: 55px;
        }
      }
    }

    code.order-number {
      color: $black;
    }
  }
}

.content-detail-page.membership {
  .redeem-membership-route,
  .buy-membership {
    .section-header {
      margin-bottom: 16px;
    }

    .membership-level .section-header {
      margin-bottom: 8px;
    }
  }
}

.select-available-session, .date-selector {
  .selectable-button {
    &.available {
      @include hover {
        border-color: $gold;
        color: $gold;
      }
    }
  }
}

.section-header {
  h3 {
    font-size: 18px;
  }
}

.date-picker-wrapper {
  .picker-month table caption {
    font-size: 20px;
  }
  .picker-day {
    .date {
      font-size: 18px;
    }

    .availability-status {
      padding-top: 3px;
      font-size: 12px;
    }

    .price-wrapper .price {
      font-size: 14px;
    }

    .price-wrapper {
      font-size: 14px;
      padding-top: 3px;
    }

    .badges {
      padding-top: 3px;
    }
  }

  .legend .message {
    font-size: 16px;
  }
}
