.select-session-wrapper,
.select-tickets {
  margin-top: $spacing-l;
}

.reserve-quantity-first-form,
.reserve-single-event-form {
  .select-tickets {
    margin-top: 0;
  }
}

.section-sessions {
  .annotation-legend {
    margin-bottom: 12px;
  }
}

.select-tickets {
  .ticket-group-wrapper:last-child {
    .ticket-group:last-child {
      .ticket-type:last-child {
        margin-bottom: 0;
      }
    }
  }

  .ticket-type {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: $spacing-m;

    @include mediumAndLarge {
      margin-bottom: $spacing-l;
    }
  }
}

.selectable-button,
.selected-date-time {
  color: $body-color;
  font: $button-font;

  &:not(.sold-out) {
    color: $heading-color;

    .date,
    .session-time,
    .price-wrapper,
    .session-price {
      color: inherit;
    }
  }

  .dark-theme & {
    &:not(.sold-out) {
      color: rgba(255, 255, 255, 0.87);
      background-color: transparent;

      .date,
      .session-time,
      .price-wrapper,
      .session-price {
        color: inherit;
      }
    }

  }
}

.selectable-button.session {
  padding: 13px 12px 12px 12px;

  .dark-theme & {
    &:not(.sold-out) {
      .price-wrapper,
      .session-price {
        color: rgba(255, 255, 255, 0.6);
        background-color: transparent;
      }
    }
  }
}

.date-selector,
.select-available-session {
  .selectable-button {

    .price-wrapper {
      font-size: 14px;

      .from-label {
        font-size: 12px;
      }
    }

    &.sold-out {
      background-color: $error-background-color;
      border: 0;
      cursor: default;
    }

    &.selected:hover {
      border-color: transparent;
    }
  }
}

.date-selector {
  .annotation-legend {
    @include mobileExperience {
      border-bottom: $calendar-divider;
      padding-bottom: 8px;
    }
  }
}

.date-first {
  .reserve-date-first-form,
  .select-date-wrapper {
    .dynamic-messages {
      padding: 0 16px;
    }
  }

  .hurry {
    padding: 0 16px;
    margin-top: 16px;
  }

  .popout-mode .today-tomorrow-buttons {
    flex-grow: 1;
    display: flex;
  }
}

.date-picker {
  button.more-dates {
    @extend %button;
    font-size: 14px;
    padding: 0 $spacing-s;
    color: $body-color;
    border: none;
    text-shadow: none;
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.04);
    height: 40px;


    &:hover {
      background-color: rgba(0, 0, 0, 0.08);
      color: $body-color;
    }

    .dark-theme & {
      background-color: rgba(255, 255, 255, 0.08);
      color: white;

      &:hover {
        background-color: rgba(255, 255, 255, 0.12);
        color: white;
      }
    }
  }
}

.date-or-flexible-tickets {

  label.form-input.checkbox {
    input[type='checkbox'] {
      display: none;
    }
  }

  label.form-input.flexible-ticket {
    @extend %secondary-button;

    @include small {
      width: auto;
      white-space: normal;
    }

    .label {
      display: inline-block;
      vertical-align: middle;
    }
  }

  @include smallAndMedium {
    flex-wrap: wrap;

    .today-tomorrow-buttons {
      display: block;
    }

    .date-picker {
      display: block;
      margin-bottom: 0;
    }

    .flexible-ticket {
      display: block;
    }
  }
}

.selection.quantity-first {
  .quantity-first-section {
    margin-bottom: 24px;

    .stepper-group-heading {
      padding-left: 16px;
      padding-right: 16px;
    }

    &.section-sessions {
      margin-bottom: 0;
    }

    .content-wrapper {
      display: flex;

      @include desktopExperience {
        padding: 0 16px;
      }
    }

    .select-session-wrapper {
      margin-top: 0;
    }
  }

  section.names {
    border-bottom: $divider;
    padding-bottom: 25px;
    margin-bottom: 25px;
    box-sizing: content-box;

    &:empty {
      display: none;
    }
  }

  h3 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .subtitle {
    color: $body-color;
    line-height: 1.2;

    @include mediumAndLarge() {
      margin-top: $spacing-xs;
    }
  }

  .btn-find-tickets {
    width: 100%;
    margin-top: $spacing-m;
  }

  .message.error {
    margin-top: $spacing-m;
  }

  .select-date {
    margin-top: $spacing-s;
  }

  button.change {
    font-family: $primary-font;
    font-size: 16px;
    border: none;
    background-color: transparent;
    padding: 0;
    text-align: left;
    min-width: 90px;
    margin-top: $spacing-xs;
    color: $primary-color;

    @include mediumAndLarge() {
      margin-top: 0;
    }

    svg {
      fill: currentColor;
      display: inline-block;
      vertical-align: text-top;
      margin-left: 4px;
      width: 18px;
    }
  }

  .sessions {
    margin-top: 14px;
  }
}

.admit-details-fields {
  label.form-input {
    max-width: 560px;
  }
}

.date-first,
.reserve-single-event-form,
.reserve-quantity-first-form {
  @include desktopExperience {
    .date-or-flexible-tickets,
    .select-session-inner,
    .tickets {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
}

.reserve-quantity-first-form {
  @include desktopExperience {
    .date-picker {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
}
