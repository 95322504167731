input[type='radio'] {
  cursor: pointer;
  display: inline-block;
  min-width: 24px;
  width: 24px;
  height: 24px;
  margin: 0;
  border-radius: 100%;
  background-color: $radio-button-background-color;
  border: $radio-button-border-width solid $radio-button-border-color;
  appearance: none;
  position: relative;

  &:hover {
    border-color: $radio-button-hover-border-color;
  }

  &:checked {
    background-color: $radio-button-checked-background-color;
    border-color: $radio-button-checked-border-color;
    border-width: $radio-button-border-width;

    &:after {
      content: '';
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 100%;
      background-color: $radio-button-selected-color;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }

  // Firefox adds a red halo to required radio buttons
  &:required {
    box-shadow: none;
  }

  &:focus {
    box-shadow: 0 0 0 3px $very-light-gray;
  }
}

.radio-items .radio-item {
  margin-bottom: $spacing-m;

  &.selectable > .label {
    font-weight: inherit;
  }
}

input[type='checkbox'] {
  cursor: pointer;
  display: inline-block;
  width: 21px;
  height: 21px;
  margin: 0 10px 0 0;
  background: 0 0;
  -webkit-appearance: none;
  border: 1px solid $medium-gray;
  border-radius: 3px;
  top: 5px;
  position: relative;

  &:hover {
    border-color: $dark-gray;
  }

  &:checked {
    background: $primary-color;
    border-color: transparent;

    &:before {
      content: '';
      position: relative;
      display: block;
      left: 7px;
      top: 2px;
      width: 6px;
      height: 13px;
      border: solid $white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }

  &:focus {
    border-color: $medium-gray;
    box-shadow: 0 0 0 3px $very-light-gray;
  }

  &:disabled {
    opacity: 0.5;
  }
}

select {
  @extend %input;
  width: 100%;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  line-height: 1.2;
  padding-right: 35px;
  background-image: dropdownArrow($select-arrow-color);
  background-repeat: no-repeat;
  background-position: right 14px top calc(50% + 3px);
  background-size: 12px 14px;

  &:hover {
    @extend %input-hover;
  }

  &:focus {
    @extend %input-focus;
  }
}

// TODO Consider input:not([type=radio]):not([type=checkbox]) or input:not(.radio):not(.checkbox).
input[type='text'],
input[type='email'],
input[type='tel'],
input[type='password'],
input[type='number'],
input[type='date'] {
  @extend %input;
  width: 100%;

  &:enabled:hover {
    @extend %input-hover;
  }

  &:focus {
    @extend %input-focus;
  }

  &:disabled {
    opacity: 0.5;
  }
}

input[type='date'] {
  max-width: 200px;
}

.dark-theme input[type='date'] {
  color-scheme: dark;
}

.form-input {
  &.hover input {
    @extend %input-hover;
  }

  &.focus input {
    @extend %input-focus;
  }
}

.form-input.radio-group {
  margin-bottom: $spacing-m;

  > span.label {
    @extend %label;
  }
}

.disabled-field {
  margin-bottom: 16px;

  label {
    @extend %label;
  }
}

label.form-input {
  display: block;

  .label {
    cursor: pointer;
  }

  // TODO Consider input:not(.radio):not(.checkbox).
  &.text,
  &.email,
  &.tel,
  &.boolean,
  &.select,
  &.password,
  &.number,
  &.date {
    margin-bottom: $spacing-m;

    .label {
      @extend %label;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &.date {
    position: relative;

    .button-wrapper {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    .datepicker-trigger {
      display: flex;
      align-items: center;
      gap: 16px;

      svg {
        fill: currentcolor;
      }
    }
  }

  &.invalid {
    // TODO Consider input:not([type=radio]):not([type=checkbox]) or :not(.radio):not(.checkbox).
    input[type='text'],
    input[type='email'],
    input[type='tel'],
    input[type='password'],
    select,
    .datepicker-trigger {
      @extend %input-invalid;

      &:hover {
        @extend %input-invalid;
      }

      &:focus {
        @extend %input-focus;
      }
    }
  }

  &.gift-aid-checkbox .input-wrapper,
  &.checkbox {
    display: flex;
    flex-wrap: nowrap;

    input[type='checkbox'] {
      display: block;
      flex-shrink: 0;
      flex-grow: 0;
      top: 0;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.joint-input-button > .wrapper {
  input[type='text'] {
    @extend %input;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  button.apply-promo {
    @extend %primary-button;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.autocomplete-results {
  position: absolute;
  display: flex;
  flex-direction: column;
  border: 1px solid #eeeeee;
  max-height: 240px;
  width: 100%;
  background-color: white;
  z-index: 10;
  box-shadow: 0 1px 1px 1px rgb(0 0 0 / 25%);

  .dark-theme & {
    background-color: var(--page-background-color);
  }

  .autocomplete-list {
    padding: 0;
    margin: 0;
    overflow: auto;

    .autocomplete-list-item {
      text-align: left;
      padding: 1rem 4px 1rem 17px;
      margin: 0;
      cursor: pointer;
      align-items: center;
      display: flex;
      border-bottom: 1px solid #eeeeee;
      line-height: 1.3em;

      // https://stackoverflow.com/a/34559614/19395717
      // Note: Having this style in a separate container is key
      span {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }

      &.no-matches {
        cursor: default;
        color: #888;
      }

      &:not(.no-matches) {
        &.is-active,
        &:hover,
        &.hover,
        &:focus {
          background-color: $primary-color;
          color: $primary-contrast-color;
        }
      }
    }
  }

  .autocomplete-after-list-slot {
    border-top: 1px solid $light-gray;

    button.manual-mode-button.link {
      width: 100%;
      text-align: left;
      padding: 1rem 4px 1rem 17px;
      margin: 0;
      color: $link-color;

      svg {
        fill: currentColor;
        margin-right: 5px;
        stroke: transparent;
      }

      &:hover {
        color: $link-hover-color;
      }
    }
  }
}

.form-input {
  .password-input-wrapper {
    position: relative;
  }

  .password-input-wrapper input {
    width: 100%;
    padding-right: 30px;
  }

  .toggle-password {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;

    svg {
      width: 18px;
      height: 16px;
    }
  }
}
