@import 'todo/page';

body {
  margin: 0;
  padding: 0;
  color: $body-color;
  overflow-x: hidden;
  overflow-y: scroll;
}

.body-inner {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 250px;

  body.has-mobile-footer & {
    @include mobileExperience {
      padding-bottom: var(--mobile-footer-height);
    }
  }

  .site-content-inner {
    @extend %content-container;

    @include responsiveProperty(padding-top, $spacing-m, $spacing-l, 7 * $u);

    // TODO Remove this when mobile back button has been implemented
    @include mobileExperience {
      padding-bottom: 24px;
    }

    .aside {
      margin-bottom: $spacing-l;
      margin-top: 0;

      @include large {
        width: 31%;
        top: 20px;
        position: sticky;
      }
    }
  }

  > .site-content {
    flex-grow: 1;
  }

  @include smallAndMedium {
    .has-sidebar .aside {
      margin-top: $spacing-l;
    }
  }

  @include large {
    .has-sidebar {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;

      > h1 {
        flex-basis: 100%;
      }

      > .main {
        width: 69%;
        padding-right: $spacing-l;
        padding-bottom: 96px;
      }
    }
  }
}

.loader {
  @extend %loading-spinner;
  position: relative;
  height: 40px;

  &:before,
  &:after {
    width: 40px;
    height: 40px;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
}
