// TODO: Merge into styles/_messages.scss

// Not span.message
// span.message is used for inline field validation messages
div.message {
  display: flex;
  margin-bottom: 32px;
  padding: 16px;
  text-align: left;
  border: 1px solid $info-color;
  background-color: $info-background-color;
  align-items: center;

  svg {
    height: 27px;
    width: 27px;
    border-radius: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    align-self: flex-start;
    margin-top: 4px;
    margin-right: 12px;
    background-color: $info-color;
    padding: 6px;

    @include mediumAndLarge {
      align-self: center;
      margin-top: 0;
    }
  }

  &.expires-in {
    background-color: $very-light-gray;
    color: $body-color;
  }

  &.success {
    border-color: $success-color;
    background-color: $success-background-color;

    svg {
      background-color: $success-color;
    }
  }

  &.warning {
    background-color: $warning-background-color;
    border-color: $warning-color;

    svg {
      background-color: $warning-color;
    }
  }

  &.error {
    background-color: $error-background-color;
    border-color: $error-color;

    svg {
      background-color: $error-color;
    }
  }

  &.promo {
    background-color: $promo-background-color;
    border-color: $promo-color;

    svg {
      background-color: $promo-color;
      fill: $promo-color;
      padding: 4px;
    }
  }

  &.expires-in svg {
    fill: currentColor;
    background: none;
  }

  &.members-banner {
    line-height: 1.5;

    &.logged-out {
      background-color: $very-light-gray;
      border: none;
      font-size: 16px;

      svg {
        background-color: $primary-color;
        padding: 0;
      }

      a {
        color: $primary-color;

        &:hover {
          color: $secondary-color;
        }
      }
    }
  }
}
